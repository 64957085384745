<template>
    <InlineSvg :src="getIcon(iconName)" :width="width" :height="height" />
</template>

<script>
import InlineSvg from "vue-inline-svg";

export default {
    name: 'icon',
    components: {
        InlineSvg,
    },
    props: {
        iconName: {
            type: String,
            default: 'text',
        },
        width: {
            type: Number,
            default: null
        },
        height: {
            type: Number,
            default: null
        }
    },
    methods: {
        getIcon(iconName) {


            switch (iconName) {
                // FAB
                case 'add-button':
                    return require('../../images/fab-icons/icon-fab-add-button.svg');
                case 'add-link':
                    return require('../../images/fab-icons/icon-fab-add-link.svg');
                case 'add-media':
                    return require('../../images/fab-icons/icon-fab-add-media.svg');
                case 'add-page':
                    return require('../../images/fab-icons/icon-fab-add-page.svg');
                case 'add-text':
                    return require('../../images/fab-icons/icon-fab-add-text.svg');
                case 'add-testimonial':
                    return require('../../images/fab-icons/icon-fab-add-testimonial.svg');
                case 'remove-testimonial':
                    return require('../../images/fab-icons/icon-fab-remove-testimonial.svg');
                case 'brush':
                case 'designer':
                    return require('../../images/fab-icons/icon-fab-brush.svg');
                case 'col-add':
                    return require('../../images/fab-icons/icon-fab-col-add.svg');
                case 'col-remove':
                    return require('../../images/fab-icons/icon-fab-col-remove.svg');
                case 'context-menu':
                    return require('../../images/fab-icons/icon-fab-context-menu.svg');
                case 'crop':
                case 'crop-active':
                case 'crop-white':
                    return require('../../images/fab-icons/icon-fab-crop.svg');
                case 'delete':
                    return require('../../images/fab-icons/icon-fab-delete.svg');
                case 'desktop':
                    return require('../../images/fab-icons/icon-fab-desktop.svg');
                case 'down':
                case 'down-arrow':
                case 'arrow-down':
                case 'down-gray':
                    return require('../../images/fab-icons/icon-fab-down.svg');
                case 'drag':
                case 'rearrange':
                    return require('../../images/fab-icons/icon-fab-drag.svg');
                case 'edit-logo':
                    return require('../../images/fab-icons/icon-fab-edit-logo.svg');
                case 'edit-pages':
                    return require('../../images/fab-icons/icon-fab-edit-pages.svg');
                case 'effects':
                case 'effects-active':
                    return require('../../images/fab-icons/icon-fab-effects.svg');
                case 'email-settings':
                    return require('../../images/fab-icons/icon-fab-email-settings.svg');
                case 'flip':
                    return require('../../images/fab-icons/icon-fab-flip.svg');
                case 'global-spacing':
                    return require('../../images/fab-icons/icon-fab-global-spacing.svg');
                case 'idea':
                    return require('../../images/fab-icons/icon-fab-idea.svg');
                case 'image-bi-cover':
                    return require('../../images/fab-icons/icon-fab-image-bi-cover.svg');
                case 'image-bottom-align':
                    return require('../../images/fab-icons/icon-fab-image-bottom-align.svg');
                case 'image-display':
                    return require('../../images/fab-icons/icon-fab-image-display.svg');
                case 'image-display-mode':
                    return require('../../images/fab-icons/icon-fab-image-display-mode.svg');
                case 'image-fit':
                    return require('../../images/fab-icons/icon-fab-image-fit.svg');
                case 'image-fit-margin':
                    return require('../../images/fab-icons/icon-fab-image-fit-margin.svg');
                case 'image-it-large':
                    return require('../../images/fab-icons/icon-fab-image-it-large.svg');
                case 'image-it-medium':
                    return require('../../images/fab-icons/icon-fab-image-it-medium.svg');
                case 'image-it-small':
                    return require('../../images/fab-icons/icon-fab-image-it-small.svg');
                case 'image-large':
                    return require('../../images/fab-icons/icon-fab-image-large.svg');
                case 'image-medium':
                    return require('../../images/fab-icons/icon-fab-image-medium.svg');
                case 'image-null':
                case 'disabled':
                case 'not-allowed':
                case 'no-mockup':
                    return require('../../images/fab-icons/icon-fab-image-null.svg');
                case 'image-small':
                    return require('../../images/fab-icons/icon-fab-image-small.svg');
                case 'layout':
                case 'layout-active':
                    return require('../../images/fab-icons/icon-fab-layout.svg');
                case 'left':
                case 'arrow-left':
                    return require('../../images/fab-icons/icon-fab-left.svg');
                case 'lock':
                    return require('../../images/fab-icons/icon-fab-lock.svg');
                case 'unlock':
                    return  require('../../images/fab-icons/icon-fab-unlock.svg');
                case 'mobile':
                    return require('../../images/fab-icons/icon-fab-mobile.svg');
                case 'mockups':
                case 'mockups-active':
                    return require('../../images/fab-icons/icon-fab-mockups.svg');
                case 'nav-background-fill':
                    return require('../../images/fab-icons/icon-fab-nav-background-fill.svg');
                case 'nav-font-color':
                    return require('../../images/fab-icons/icon-fab-nav-font-color.svg');
                case 'pages':
                    return require('../../images/fab-icons/icon-fab-pages.svg');
                case 'page':
                    return require('../../images/general-icons/page.svg')
                case 'password':
                    return require('../../images/fab-icons/icon-fab-password.svg');
                case 'remove-media':
                    return require('../../images/fab-icons/icon-fab-remove-media.svg');
                case 'remove-page':
                    return require('../../images/fab-icons/icon-fab-remove-page.svg');
                case 'remove-thumbnail':
                    return require('../../images/fab-icons/icon-fab-remove-thumbnail.svg');
                case 'reorder-arrows':
                    return require('../../images/fab-icons/icon-fab-reorder.svg');
                case 'replace':
                    return require('../../images/fab-icons/icon-fab-replace.svg');
                case 'right':
                case 'arrow-right':
                    return require('../../images/fab-icons/icon-fab-right.svg');
                case 'arrow-horizontal':
                    return require('../../images/arrows/arrow-horizontal.svg')
                case 'rotate':
                case 'rotate-mockup':
                    return require('../../images/fab-icons/icon-fab-rotate-mockup.svg');
                case 'change-background':
                case 'set-background':
                case 'image-cover':
                    return require('../../images/fab-icons/icon-fab-set-background.svg');
                case 'settings':
                case 'settings-light':
                    return require('../../images/fab-icons/icon-fab-settings.svg');
                case 'space-decrease':
                    return require('../../images/fab-icons/icon-fab-space-decrease.svg');
                case 'space-increase':
                    return require('../../images/fab-icons/icon-fab-space-increase.svg');
                case 'design':
                case 'style':
                    return require('../../images/fab-icons/icon-fab-style.svg');
                case 'success':
                    return require('../../images/fab-icons/icon-fab-success.svg');
                case 'tablet':
                    return require('../../images/fab-icons/icon-fab-tablet.svg');
                case 'thumbnail-layout':
                    return require('../../images/fab-icons/icon-fab-thumbnail-layout.svg');
                case 'thumbnails':
                    return require('../../images/fab-icons/icon-fab-thumbnails.svg');
                case 'up':
                case 'arrow-up':
                    return require('../../images/fab-icons/icon-fab-up.svg');
                case 'arrow-out':
                    return require("../../images/arrows/arrow-out.svg")
                case 'change-image':
                case 'change-image-active':
                case 'upload':
                    return require('../../images/fab-icons/icon-fab-upload.svg');
                case 'history':
                    return require("../../images/fab-icons/icon-fab-history.svg");
                case 'filters':
                    return require("../../images/fab-icons/icon-fab-filters.svg")

                // general icons
                case 'add':
                    return require('../../images/general-icons/add.svg');
                case 'add-case-study-gradient':
                    return require('../../images/general-icons/add-case-study-gradient.svg');
                case 'back':
                    return require('../../images/general-icons/back.svg');
                case 'check':
                case 'checkmark':
                case 'ok':
                    return require('../../images/general-icons/checkmark.svg');
                case 'chevron-down':
                    return require('../../images/general-icons/chev-down.svg');
                case 'chevron-left':
                    return require('../../images/general-icons/chev-left.svg');
                case 'chevron-right':
                    return require('../../images/general-icons/chev-right.svg');
                case 'chevron-up':
                    return require('../../images/general-icons/chev-up.svg');
                case 'copy':
                case 'duplicate':
                    return require('../../images/general-icons/copy.svg');
                case 'draft':
                    return require('../../images/general-icons/draft.svg');
                case 'caret-down':
                case 'dropdown-select':
                    return require('../../images/general-icons/dropdown-select.svg');
                case 'dark':
                    return require('../../images/general-icons/dark.svg');
                case 'figma-mono':
                    return require('../../images/general-icons/figma-mono.svg');
                case 'font-size-icon':
                    return require('../../images/general-icons/font-size-icon.svg');
                case 'hidden':
                case 'notvisible':
                    return require('../../images/general-icons/hidden.svg');
                case 'hidden-gradient':
                    return require('../../images/general-icons/hidden-gradient.svg');
                case 'info':
                    return require('../../images/general-icons/info.svg');
                case 'italic':
                    return require('../../images/general-icons/italic.svg');
                case 'light':
                    return require('../../images/general-icons/light.svg');
                case 'line-height-icon':
                    return require('../../images/general-icons/line-height-icon.svg');
                case 'public':
                    return require('../../images/general-icons/public.svg');
                case 'publish':
                    return require('../../images/general-icons/publish.svg');
                case 'unpublish':
                    return require('../../images/general-icons/unpublish.svg');
                case 'review':
                    return  require('../../images/general-icons/review.svg');
                case 'share':
                    return require('../../images/general-icons/share.svg');
                case 'visible':
                case 'preview':
                    return require('../../images/general-icons/visible.svg');
                case 'no-index':
                    return require('../../images/general-icons/no-index.svg');
                case 'notification':
                    return require('../../images/general-icons/notification.svg');
                case 'gift':
                    return require('../../images/general-icons/gift.svg');
                case 'paste':
                    return require('../../images/general-icons/icon-paste.svg')
                case 'payment':
                    return require('../../images/general-icons/payment.svg');
                case 'profile':
                    return require('../../images/general-icons/profile.svg');
                case 'reorder':
                    return require('../../images/general-icons/reorder.svg')
                case 'warning':
                    return require('../../images/general-icons/warning.svg');
                case 'web':
                    return require('../../images/general-icons/web.svg');
                case 'discount':
                    return require('../../images/general-icons/discount.svg');
                case 'thumbs-up':
                    return require('../../images/general-icons/thumb_up.svg');
                case 'thumbs-down':
                    return require('../../images/general-icons/thumb_down.svg');


                // text editor
                case 'alt':
                    return require('../../images/text-editor-icons/alt-text.svg');
                case 'x':
                case 'close':
                case 'close-light':
                    return require('../../images/text-editor-icons/close-light.svg');
                case 'done':
                    return require('../../images/text-editor-icons/done.svg');
                case 'edit':
                    return require('../../images/text-editor-icons/edit.svg');
                case 'edit-link':
                    return require('../../images/text-editor-icons/edit-link.svg');
                case 'remove-colors':
                    return require('../../images/text-editor-icons/remove-colors.svg');
                case 'style-thick':
                    return require('../../images/text-editor-icons/style-thick.svg');
                case 'text-bold':
                    return require('../../images/text-editor-icons/text-bold.svg');
                case 'text-center':
                    return require('../../images/text-editor-icons/text-center.svg');
                case 'text-format-remove':
                    return require('../../images/text-editor-icons/text-format-remove.svg');
                case 'text-h':
                    return require('../../images/text-editor-icons/text-h.svg');
                case 'text-h1':
                    return require('../../images/text-editor-icons/text-h1.svg');
                case 'text-h2':
                    return require('../../images/text-editor-icons/text-h2.svg');
                case 'text-h3':
                    return require('../../images/text-editor-icons/text-h3.svg');
                case 'text-h4':
                    return require('../../images/text-editor-icons/text-h4.svg');
                case 'text-italic':
                    return require('../../images/text-editor-icons/text-italic.svg');
                case 'text-left':
                    return require('../../images/text-editor-icons/text-left.svg');
                case 'link':
                case 'text-link':
                    return require('../../images/text-editor-icons/text-link.svg');
                case 'text-ol':
                    return require('../../images/text-editor-icons/text-ol.svg');
                case 'text-p':
                    return require('../../images/text-editor-icons/text-p.svg');
                case 'text-p1':
                    return require('../../images/text-editor-icons/text-p1.svg');
                case 'text-p2':
                    return require('../../images/text-editor-icons/text-p2.svg');
                case 'text-quote':
                    return require('../../images/text-editor-icons/text-quote.svg');
                case 'text-right':
                    return require('../../images/text-editor-icons/text-right.svg');
                case 'text-ul':
                    return require('../../images/text-editor-icons/text-ul.svg');
                case 'text-underline':
                    return require('../../images/text-editor-icons/text-underline.svg');
                case 'unlink':
                case 'text-unlink':
                    return require('../../images/text-editor-icons/text-unlink.svg');

                // crop icons
                case 'crop_1_1':
                    return require('../../images/crop-options/crop_1_1.svg');
                case 'crop_2_3':
                    return require('../../images/crop-options/crop_2_3.svg');
                case 'crop_3_2':
                    return require('../../images/crop-options/crop_3_2.svg');
                case 'crop_4_5':
                    return require('../../images/crop-options/crop_4_5.svg');
                case 'crop_5_4':
                    return require('../../images/crop-options/crop_5_4.svg');
                case 'crop_9_16':
                    return require('../../images/crop-options/crop_9_16.svg');
                case 'crop_16_9':
                    return require('../../images/crop-options/crop_16_9.svg');
                case 'crop_circle':
                    return require('../../images/crop-options/crop_circle.svg');
                case 'crop_custom':
                    return require('../../images/crop-options/crop_custom.svg');
                case 'crop_original':
                    return require('../../images/crop-options/crop_original.svg');

                // aspect ratio options
                case 'aspect-ratio-1_1':
                    return require('../../images/aspect-ratios/1_1.svg');
                case 'aspect-ratio-3_4':
                    return require('../../images/aspect-ratios/3_4.svg');
                case 'aspect-ratio-4_3':
                    return require('../../images/aspect-ratios/4_3.svg');
                case 'aspect-ratio-9_16':
                    return require('../../images/aspect-ratios/9_16.svg');
                case 'aspect-ratio-16_9':
                    return require('../../images/aspect-ratios/16_9.svg');
                case 'aspect-ratio-Original':
                    return require('../../images/aspect-ratios/Original.svg');

                // gallery layouts
                case 'layout-carousel':
                    return require('../../images/gallery-layouts/layout-carousel.svg');
                case 'layout-grid':
                    return require('../../images/gallery-layouts/layout-grid.svg');
                case 'layout-slider':
                    return require('../../images/gallery-layouts/layout-slider.svg');
                case 'layout-tilted':
                    return require('../../images/gallery-layouts/layout-tilted.svg');

                // mockup icons
                case 'colorScheme-dark':
                    return require('../../images/mockup-icons/color-dark.svg');
                case 'colorScheme-light':
                    return require('../../images/mockup-icons/color-light.svg');
                case 'settings-notch':
                    return require('../../images/mockup-icons/settings-notch.svg');
                case 'settings-island':
                    return require('../../images/mockup-icons/settings-island.svg');
                case 'settings-simple':
                    return require('../../images/mockup-icons/settings-simple.svg');
                case 'settings-rounded':
                    return require('../../images/mockup-icons/corner-rounded.svg');
                case 'settings-squared':
                    return require('../../images/mockup-icons/corner-squared.svg');
                case 'shadow-no-shadow':
                    return require('../../images/mockup-icons/effects-no-shadow.svg');
                case 'shadow-shadow':
                    return require('../../images/mockup-icons/effects-shadow.svg');
                case 'mockupType-browser':
                    return require('../../images/mockup-icons/style-browser.svg');
                case 'mockupType-outline':
                    return require('../../images/mockup-icons/style-outline.svg');
                case 'mockupType-semirealistic':
                    return require('../../images/mockup-icons/style-semi-realistic.svg');
                case 'mockupType-realistic':
                    return require('../../images/mockup-icons/style-realistic.svg');
                case 'mockupType-simple':
                    return require('../../images/mockup-icons/style-simple.svg');
                case 'mockupType-minimal':
                    return require('../../images/mockup-icons/corner-rounded.svg');
                case 'deviceType-laptop':
                    return require('../../images/mockup-icons/type-laptop.svg');
                case 'deviceType-mobile':
                    return require('../../images/mockup-icons/type-mobile.svg');
                case 'deviceType-desktop':
                    return require('../../images/mockup-icons/type-desktop.svg');
                case 'deviceType-none':
                    return require('../../images/mockup-icons/type-none.svg');
                case 'deviceType-tablet':
                    return require('../../images/mockup-icons/type-tablet.svg');

                //  Thumbnail Generator
                //mobile layouts
                case 'mobile_single_middle':
                    return require("../../images/thumbnail-generation/layouts/mobile/mobile_single_middle.svg");
                case 'mobile_single_slided':
                    return require("../../images/thumbnail-generation/layouts/mobile/mobile_single_slided.svg");
                case 'mobile_dual_middle':
                    return require("../../images/thumbnail-generation/layouts/mobile/mobile_dual_middle.svg");
                case 'mobile_dual_slided':
                    return require("../../images/thumbnail-generation/layouts/mobile/mobile_dual_slided.svg");
                case 'mobile_triple_middle':
                    return require("../../images/thumbnail-generation/layouts/mobile/mobile_triple_middle.svg");
                case 'mobile_triple_slided':
                    return require("../../images/thumbnail-generation/layouts/mobile/mobile_triple_slided.svg");
                case 'mobile_triple_slided_rotated':
                    return require("../../images/thumbnail-generation/layouts/mobile/mobile_triple_slided_rotated.svg");
                case 'mobile_triple_middle_horizontal':
                    return require("../../images/thumbnail-generation/layouts/mobile/mobile_triple_middle_horizontal.svg");
                case 'mobile_quadruple_slided':
                    return require("../../images/thumbnail-generation/layouts/mobile/mobile_quadruple_slided.svg");
                case 'mobile_quadruple_slided_rotated':
                    return require("../../images/thumbnail-generation/layouts/mobile/mobile_quadruple_slided_rotated.svg");

                    //Desktop layouts
                case 'desktop_single_middle':
                    return require("../../images/thumbnail-generation/layouts/desktop/desktop_single_middle.svg");
                case 'desktop_single_slided':
                    return require("../../images/thumbnail-generation/layouts/desktop/desktop_single_slided.svg");
                case 'desktop_mobile_overlay':
                    return require("../../images/thumbnail-generation/layouts/desktop/desktop_mobile_overlay.svg");
                case 'desktop_mobile_middle':
                    return require("../../images/thumbnail-generation/layouts/desktop/desktop_mobile_middle.svg");
                case 'desktop_single_slided_big':
                    return require("../../images/thumbnail-generation/layouts/desktop/desktop_single_slided_big.svg");
                case 'desktop_triple_variety':
                    return require("../../images/thumbnail-generation/layouts/desktop/desktop_triple_variety.svg");

                    //Tablet layouts
                case 'tablet_single_middle':
                    return require("../../images/thumbnail-generation/layouts/tablet/tablet_single_middle.svg");
                case 'tablet_single_slided':
                    return require("../../images/thumbnail-generation/layouts/tablet/tablet_single_slided.svg");
                case 'tablet_mobile_overlay':
                    return require("../../images/thumbnail-generation/layouts/tablet/tablet_mobile_overlay.svg");
                case 'tablet_mobile_middle':
                    return require("../../images/thumbnail-generation/layouts/tablet/tablet_mobile_middle.svg");
                case 'tablet_single_slided_big':
                    return require("../../images/thumbnail-generation/layouts/tablet/tablet_single_slided_big.svg");

                    //Monitor layouts
                case 'monitor_single_middle':
                    return require("../../images/thumbnail-generation/layouts/monitor/monitor_single_middle.svg");
                case 'monitor_mobile_middle':
                    return require("../../images/thumbnail-generation/layouts/monitor/monitor_mobile_middle.svg");
                case 'monitor_desktop_middle':
                    return require("../../images/thumbnail-generation/layouts/monitor/monitor_desktop_middle.svg");
                case 'monitor_tablet_middle':
                    return require("../../images/thumbnail-generation/layouts/monitor/monitor_tablet_middle.svg");
                case 'monitor_triple_variety':
                    return require("../../images/thumbnail-generation/layouts/monitor/monitor_triple_variety.svg");
                case 'monitor_quadruple_variety':
                    return require("../../images/thumbnail-generation/layouts/monitor/monitor_quadruple_variety.svg");

                    //device styles
                case 'device_style_browser':
                    return require("../../images/thumbnail-generation/icons/device-styles/device_style_browser.svg");
                case 'device_style_no_device':
                    return require("../../images/thumbnail-generation/icons/device-styles/device_style_no-device.svg");
                case 'device_style_outline':
                    return require("../../images/thumbnail-generation/icons/device-styles/device_style_outline.svg");
                case 'device_style_semi_realistic':
                    return require("../../images/thumbnail-generation/icons/device-styles/device_style_semi-realistic.svg");
                case 'device_style_simple':
                    return require("../../images/thumbnail-generation/icons/device-styles/device_style_simple.svg");

                    //background styles
                case 'background_gradient':
                    return require("../../images/thumbnail-generation/icons/background-styles/background_gradient.svg");
                case 'background_transparent':
                    return require("../../images/thumbnail-generation/icons/background-styles/background_transparent.svg");
                case 'background_solid':
                    return require("../../images/thumbnail-generation/icons/background-styles/background_solid.svg");

                    //color styles
                case 'color_light':
                    return require("../../images/thumbnail-generation/icons/color-styles/device_color_light.svg");
                case 'color_dark':
                    return require("../../images/thumbnail-generation/icons/color-styles/device_color_dark.svg");

                    //corner styles
                case 'device_corner_rounded':
                    return require("../../images/thumbnail-generation/icons/corner-styles/device_corner_rounded.svg");
                case 'device_corner_squareed':
                    return require("../../images/thumbnail-generation/icons/corner-styles/device_corner_squared.svg");

                    //gradient styles styles
                case 'gradient_linear_1':
                    return require("../../images/thumbnail-generation/icons/gradient-styles/gradient_linear_1.svg");
                case 'gradient_linear_2':
                    return require("../../images/thumbnail-generation/icons/gradient-styles/gradient_linear_2.svg");
                case 'gradient_linear_3':
                    return require("../../images/thumbnail-generation/icons/gradient-styles/gradient_linear_3.svg");
                case 'gradient_radial_1':
                    return require("../../images/thumbnail-generation/icons/gradient-styles/gradient_radial_1.svg");

                //  Design panel - Thumbnail  //

                    //Presets
                case 'preset1':
                    return require('../../images/thumbnail-alignments/presets/small-block_below_center_center.svg');
                case 'preset3':
                    return require('../../images/thumbnail-alignments/presets/small-block-slided--hover--center--center.svg');
                case 'preset4':
                    return require('../../images/thumbnail-alignments/presets/small-block-slided--below--left--center.svg');
                case 'preset7':
                    return require('../../images/thumbnail-alignments/presets/large-block-mixed--hover--center--center.svg');
                case 'preset8':
                    return require('../../images/thumbnail-alignments/presets/large-block_below_left_center.svg');
                case 'preset9':
                    return require('../../images/thumbnail-alignments/presets/small-block-text_right_left_center.svg');
                case 'preset12':
                    return require('../../images/thumbnail-alignments/presets/medium-block-text_alternating_center_center.svg');

                //  Layouts
                case 'largeBlock':
                case 'large-block':
                    return require("../../images/thumbnail-alignments/layout/large-block.svg");
                case 'mediumBlockText':
                case 'medium-block-text':
                    return require("../../images/thumbnail-alignments/layout/medium-block-text.svg");
                case 'mixedBlockLarge':
                case 'large-block-mixed':
                    return require("../../images/thumbnail-alignments/layout/large-block-mixed.svg");
                case 'smallBlock':
                case 'small-block':
                    return require("../../images/thumbnail-alignments/layout/small-block.svg");
                case 'smallBlockSlide':
                case 'small-block-slided':
                    return require("../../images/thumbnail-alignments/layout/small-block-slided.svg");
                case 'smallBlockText':
                case 'small-block-text':
                    return require("../../images/thumbnail-alignments/layout/small-block-text.svg");

                //Text Alignments
                case 'aboveAlignment':
                    return require("../../images/thumbnail-alignments/text-alignments/above_alignment.svg");
                case 'alternatingAlignment':
                    return require("../../images/thumbnail-alignments/text-alignments/alternating_alignment.svg");
                case 'belowAlignment':
                    return require("../../images/thumbnail-alignments/text-alignments/below_alignment.svg");
                case 'hiddenAlignment':
                    return require("../../images/thumbnail-alignments/text-alignments/hidden_alignment.svg");
                case 'hoverAlignment':
                    return require("../../images/thumbnail-alignments/text-alignments/hover_alignment.svg");
                case 'insideAlignment':
                    return require("../../images/thumbnail-alignments/text-alignments/inside_alignment.svg");
                case 'leftAlignment':
                    return require("../../images/thumbnail-alignments/text-alignments/left_alignment.svg");
                case 'rightAlignment':
                    return require("../../images/thumbnail-alignments/text-alignments/right_alignment.svg");


                //  Title alignments
                case 'horizontalCenter':
                    return require("../../images/thumbnail-alignments/title-alignments/horizontal/horizontal_center.svg");
                case 'horizontalLeft':
                    return require("../../images/thumbnail-alignments/title-alignments/horizontal/horizontal_left.svg");
                case 'horizontalRight':
                    return require("../../images/thumbnail-alignments/title-alignments/horizontal/horizontal_right.svg");
                case 'verticalBottom':
                    return require("../../images/thumbnail-alignments/title-alignments/vertical/vertical_bottom.svg");
                case 'verticalCenter':
                    return require("../../images/thumbnail-alignments/title-alignments/vertical/vertical_center.svg");
                case 'verticalTop':
                    return require("../../images/thumbnail-alignments/title-alignments/vertical/vertical_top.svg");

                // colors
                case 'whiteText':
                    return require("../../images/thumbnail-alignments/color-switch/white_text.svg");
                case 'blackText':
                    return require("../../images/thumbnail-alignments/color-switch/black_text.svg");

                // Divider lines //

                //  text position icons
                case 'text_position_full_width_above':
                    return require('../../images/divider-line/icons/text-position/text-position--full-width--above.svg');
                case 'text_position_full_width_below':
                    return require('../../images/divider-line/icons/text-position/text-position--full-width--below.svg');
                case 'text_position_full_width_inline':
                    return require('../../images/divider-line/icons/text-position/text-position--full-width--inline.svg');
                case 'text_position_full_width_no_text':
                    return require('../../images/divider-line/icons/text-position/text-position--full-width--no-text.svg');

                //  text position - squared
                case 'above':
                    return require('../../images/divider-line/layout/above.svg');
                case 'below':
                    return require('../../images/divider-line/layout/below.svg');
                case 'in_line':
                    return require('../../images/divider-line/layout/in_line.svg');
                case 'no_text':
                    return require('../../images/divider-line/layout/no_text.svg');


                // line weight
                case 'weight_light':
                    return require('../../images/divider-line/icons/weight/weight--light.svg');
                case 'weight_medium':
                    return require('../../images/divider-line/icons/weight/weight--medium.svg');
                case 'weight_bold':
                    return require('../../images/divider-line/icons/weight/weight--bold.svg');

                // background generator thumbnails
                case 'bg_style_1':
                    return require('../../images/background_generator/thumbnails/bg_style_1.svg');
                case 'bg_style_2':
                    return require('../../images/background_generator/thumbnails/bg_style_2.svg');
                case 'bg_style_3':
                    return require('../../images/background_generator/thumbnails/bg_style_3.svg');
                case 'bg_style_4':
                    return require('../../images/background_generator/thumbnails/bg_style_4.svg');
                case 'bg_style_5':
                    return require('../../images/background_generator/thumbnails/bg_style_5.svg');
                case 'bg_style_6':
                    return require('../../images/background_generator/thumbnails/bg_style_6.svg');
                case 'bg_style_7':
                    return require('../../images/background_generator/thumbnails/bg_style_7.svg');
                case 'bg_style_8':
                    return require('../../images/background_generator/thumbnails/bg_style_8.svg');
                case 'bg_style_9':
                    return require('../../images/background_generator/thumbnails/bg_style_9.svg');

                // General settings
                // Content alignment
                case 'content-align-left':
                    return require('../../images/general-alignment/content-align-left.svg');
                case 'content-align-center':
                    return require('../../images/general-alignment/content-align-center.svg');
                case 'content-align-right':
                    return require('../../images/general-alignment/content-align-right.svg');
                case 'content-align-top':
                    return require('../../images/general-alignment/content-align-top.svg');
                case 'content-align-middle':
                    return require('../../images/general-alignment/content-align-middle.svg');
                case 'content-align-bottom':
                    return require('../../images/general-alignment/content-align-bottom.svg');

                // TYPE SELECTOR
                // background styles
                case 'type-selector-image':
                    return require("../../images/background_generator/types/background-type-image.svg");
                case 'type-selector-gradient':
                    return require("../../images/background_generator/types/background-type-gradient.svg");
                case 'type-selector-solid':
                case 'type-selector-solid_color':
                    return require("../../images/background_generator/types/background-type-solid.svg");
                case 'type-selector-none':
                case 'type-selector-transparent':
                    return require("../../images/background_generator/types/background-type-none.svg");

                // gallery layouts
                case 'type-selector-gallery-grid':
                    return require("../../images/gallery-layouts/gallery-grid.svg");
                case 'type-selector-gallery-carousel':
                    return require("../../images/gallery-layouts/gallery-carousel.svg");
                case 'type-selector-gallery-slider':
                    return require("../../images/gallery-layouts/gallery-slider.svg");
                case 'type-selector-gallery-tilted':
                    return require("../../images/gallery-layouts/gallery-tilted.svg");

                // devices
                case 'type-selector-deviceType-none':
                    return require('../../images/mockup-icons/device-none.svg');
                case 'type-selector-deviceType-Mobile':
                    return require('../../images/mockup-icons/device-mobile.svg');
                case 'type-selector-deviceType-Tablet':
                    return require('../../images/mockup-icons/device-tablet.svg');
                case 'type-selector-deviceType-Laptop':
                    return require('../../images/mockup-icons/device-laptop.svg');
                case 'type-selector-deviceType-Desktop':
                    return require('../../images/mockup-icons/device-desktop.svg');

                // case study grid layouts
                case 'type-selector-large_list':
                    return require("../../images/thumbnail-alignments/layout/large-grid.svg");
                case 'type-selector-medium_list':
                    return require("../../images/thumbnail-alignments/layout/large-list.svg");
                case 'type-selector-highlight_grid':
                    return require("../../images/thumbnail-alignments/layout/featured-grid.svg");
                case 'type-selector-grid':
                    return require("../../images/thumbnail-alignments/layout/grid.svg");
                case 'type-selector-offset_grid':
                    return require("../../images/thumbnail-alignments/layout/offset.svg");
                case 'type-selector-small_list':
                    return require("../../images/thumbnail-alignments/layout/list.svg");

                // section icons
                case 'articles':
                    return require('../../images/section-icons/articles.svg');
                case 'awards':
                    return require('../../images/section-icons/awards.svg');
                case 'bio':
                    return require('../../images/section-icons/bio.svg');
                case 'card-sorting':
                case 'cardsorting':
                case 'projectgrid':
                    return require('../../images/section-icons/card-sorting.svg');
                case 'cj':
                case 'customerjourney':
                    return require('../../images/section-icons/cj.svg');
                case 'columns':
                    return require('../../images/section-icons/columns.svg');
                case 'contactform':
                case 'contact-form':
                    return require('../../images/section-icons/contact-form.svg');
                case 'cta':
                case 'contact':
                    return require('../../images/section-icons/cta.svg');
                case 'dividerline':
                case 'divider':
                    return require('../../images/section-icons/divider.svg');
                case 'education':
                    return require('../../images/section-icons/education.svg');
                case 'experience':
                    return require('../../images/section-icons/experience.svg');
                case 'gallery':
                    return require('../../images/section-icons/gallery.svg');
                case 'header':
                case 'subheader':
                    return require('../../images/section-icons/header.svg');
                case 'hobbies':
                    return require('../../images/section-icons/hobbies.svg');
                case 'image':
                    return require('../../images/section-icons/image.svg');
                case 'imagetext':
                case 'image-text':
                case 'textandmedia':
                    return require('../../images/section-icons/image-text.svg');
                case 'interviews':
                    return require('../../images/section-icons/interviews.svg');
                case 'logowall':
                case 'logo-wall':
                    return require('../../images/section-icons/logo-wall.svg');
                case 'media':
                    return require('../../images/section-icons/media.svg');
                case 'navigation':
                    return require('../../images/section-icons/navigation.svg');
                case 'personas':
                case 'socialmedia':
                    return require('../../images/section-icons/personas.svg');
                case 'prototype':
                    return require('../../images/section-icons/prototype.svg');
                case 'short-intro':
                case 'shortintro':
                    return require('../../images/section-icons/short-intro.svg');
                case 'sketches':
                    return require('../../images/section-icons/sketches.svg');
                case 'skills':
                case 'services':
                    return require('../../images/section-icons/skills.svg');
                case 'statement':
                case 'bold-statement':
                    return require('../../images/section-icons/statement.svg');
                case 'statistics':
                case 'stat':
                case 'stats':
                    return require('../../images/section-icons/stats.svg');
                case 'surveys':
                    return require('../../images/section-icons/surveys.svg');
                case 'testimonial':
                case 'testimonials':
                    return require('../../images/section-icons/testimonial.svg');
                case 'text':
                    return require('../../images/section-icons/text.svg');
                case 'ui-design':
                case 'uidesign':
                case 'uishowcase':
                case 'uiDesign':
                    return require('../../images/section-icons/ui-design.svg');
                case 'uj':
                case 'userjourney':
                    return require('../../images/section-icons/uj.svg');
                case 'user-testing':
                case 'usabilitytesting':
                    return require('../../images/section-icons/user-testing.svg');
                case 'video':
                case 'embed':
                    return require('../../images/section-icons/video.svg');
                case 'wireframes':
                    return require('../../images/section-icons/wireframes.svg');
                case 'adplist':
                    return require('../../images/section-icons/adplist.svg');
                case 'overview':
                    return require('../../images/section-icons/overview.svg');
                case 'problemandsolution':
                    return require('../../images/section-icons/problem-and-solution.svg');
                case 'goals':
                    return require('../../images/section-icons/goals.svg');
                case 'projectdetails':
                    return require('../../images/section-icons/project-details.svg');
                case 'learningsandnextsteps':
                    return require('../../images/section-icons/learnings-and-next-steps.svg');
                case 'businessimpact':
                    return require('../../images/section-icons/business-impact.svg');
                case 'designsystem':
                    return require('../../images/section-icons/design-system.svg');
                case 'competitors':
                case 'competitiveresearch':
                    return require('../../images/section-icons/competitors.svg');

                // page icons
                case 'page-home':
                    return require('../../images/page-icons/icon-page-home.svg');
                case 'page-about':
                    return require('../../images/page-icons/icon-page-about.svg');
                case 'page-blank':
                    return require('../../images/page-icons/icon-page-blank.svg');
                case 'page-contact':
                    return require('../../images/page-icons/icon-page-contact.svg');
                case 'page-link':
                    return require('../../images/page-icons/icon-page-link.svg');
                case 'page-file':
                    return require('../../images/page-icons/icon-page-file.svg');
                case 'page-mailto':
                    return require('../../images/page-icons/icon-page-mailto.svg');

                //Illustration svg-s
                case 'plus-plan':
                case 'vector':
                    return require('../../images/illustrations/vector.svg');
                case 'mark':
                    return require('../../images/illustrations/mark.svg');
                case 'rocket':
                    return require('../../images/illustrations/rocket.svg');
                case 'check-lotti':
                    return require('../../images/checkmark-lotti.svg');
                case 'status-indicator':
                    return require('../../images/illustrations/status-indicator.svg');
                case 'dot':
                    return require('../../images/illustrations/dot.svg');
                case 'confetti':
                    return require('../../images/illustrations/confetti.svg');

                // Embedded media logos
                case 'adobe':
                    return require('../../images/icon-embed-media-adobe.svg');
                case 'airtable':
                    return require('../../images/icon-embed-media-airtable.svg');
                case 'axshare':
                    return require('../../images/icon-embed-media-axure.svg');
                case 'figjam':
                    return require('../../images/icon-embed-media-figma.svg');
                case 'figma':
                    return require('../../images/icon-embed-media-figma.svg');
                case 'framer':
                    return require('../../images/icon-embed-media-framer.svg');
                case 'google':
                    return require('../../images/icon-embed-media-google.svg');
                case 'invision':
                    return require('../../images/icon-embed-media-invision.svg');
                case 'marvel':
                    return require('../../images/icon-embed-media-marvel.svg');
                case 'miro':
                    return require('../../images/icon-embed-media-miro.svg');
                case 'protopie':
                    return require('../../images/icon-embed-media-protopie.svg');
                case 'vimeo':
                    return require('../../images/icon-embed-media-vimeo.svg');
                case 'youtube':
                    return require('../../images/icon-embed-media-youtube.svg');

                // Embedded media logos
                case 'searchIcon':
                    return require("../../images/general-icons/search-icon.svg");
                case 'proPickIcon':
                    return require("../../images/general-icons/our-picks-category-icon.svg");
                case 'serifIcon':
                    return require("../../images/general-icons/serif-category-icon.svg");
                case 'sansIcon':
                    return require("../../images/general-icons/sans-category-icon.svg");
                case 'monoIcon':
                    return require("../../images/general-icons/mono-category-icon.svg");

                //Review
                case 'tooltip-arrow':
                    return require('../../images/review/tour-arrow.svg');
                case 'positive-aspect':
                    return require('../../images/review/positive-aspect-category.svg')
                case 'areas-of-improvement':
                    return require('../../images/review/areas-of-improvement-category.svg')
                case 'progress-indicator-empty':
                    return require('../../images/review/progress-indicator-empty.svg')
                case 'progress-indicator-step1':
                    return require('../../images/review/progress-indicator-step1.svg')
                case 'progress-indicator-step2':
                    return require('../../images/review/progress-indicator-step2.svg')
                case 'progress-indicator-finished':
                    return require('../../images/review/progress-indicator-finished.svg')
                case 'comment':
                    return require('../../images/review/comment.svg')
                case 'chat':
                    return require('../../images/review/chat.svg')
                case 'reaction':
                    return require('../../images/review/reaction.svg')
                case 'send':
                    return require('../../images/icon-send.svg')
                case 'dots':
                    return require('../../images/icon-more.svg')

                //ADP list related icons
                case 'adplist-logo':
                    return require('../../images/adplist/adplist-logo.svg')
                case 'attendance-icon':
                    return require('../../images/adplist/attendance-icon.svg')
                case 'mentoring-time-icon':
                    return require('../../images/adplist/mentoring-time-icon.svg')
                case 'sessions-icon':
                    return require('../../images/adplist/sessions-icon.svg')
                case 'book-session':
                    return require('../../images/adplist/book-session.svg')

                // domain states
                case 'active-checkmark':
                    return require("../../images/ic-checkmark-green.svg");
                case 'connecting':
                    return require("../../images/ic-loader.svg");
                case 'waiting':
                    return require("../../images/icon-time.svg");
                case 'not_connected':
                case 'inactive':
                    return require("../../images/icon-danger.svg");
                case 'old_server':
                    return require("../../images/ic-disabled.svg");
 
                //AI Related
                case 'ai-sparkling-icon':
                    return require('../../images/ai-icons/ai-sparkling.svg')
                default:
                    console.error(`Image route not defined for section type: ${iconName}`);
                    return require('../../images/general-icons/mono-category-icon.svg');
            }
        }
    }
}

</script>
