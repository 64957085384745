import {createStore} from 'vuex'
import Router from '../router/Router.js';
import autosize from 'autosize';
import {PortfolioActions} from "./actions/PortfolioActions.js";
import {UpdateSection} from "./actions/SectionActions.js";
import UsersAPI from "../api/users/UserAPI.js";
import {event} from 'vue-gtag';
import {
    DesignPanelPathsEnum,
    EditedImageTypeEnum,
    PageTypesEnum
} from "../common/enums.js";
import {aboutPageSections, basicSections, homePageRecommendedSections, uxMethods} from "../sections/_SectionTypes.js";
import {
    getAllTextStyles,
    getCssVarsFromColorPalette,
    getPortfolioHeroTextStyles
} from "../common/TextStyleRenderHelper.js";
import { review } from './modules/reviewStore.js';
import {defaultMockup} from "@/scripts/common/mockupStore/mockupStore.js";
import {resolveColor} from "@/scripts/common/helpers.js";
import MixpanelService from "@/scripts/analytics/mixpanel.js";


/* Create a reactive store.state */
export const store = createStore({
    modules: {
        review,
    },
    state() {
        return {
            triggerAnimation: true,
            recalculateScrollTrigger: true,
            emergencyModeSettings: null,
            user: null,
            userPortfolios: null,
            portfolio: null,
            portfolioSetupComplete: false,
            portfolioPages: null,
            caseStudyPages: null,
            allCaseStudyPages: null,
            allPortfolios: null,
            selectedPortfolioForDomain: null,
            userSettings: {
                currentSubscription : null,
                currentSubscriptionPlan : null,
                domainSubscriptions: [],
                invoices: [],
                stripePrices: {
                    monthlyPrice: {
                        id:'',
                        price:'NaN'
                    },
                    yearlyPrice: {
                        id:'',
                        price:'NaN'
                    }
                },
                referrerData:null,
                referredData:null,
                creditBalance:0,
                showSupportSurvey: false,
                showTrialStartedAlert: false
            },
            project: null,
            allowedSections: null,
            sectionEditorSettings: {
                pageToEdit: null,
                emptyStateTreshold: 1,
                hideEditButtons: false,
                hideMockupButtons: false,
                hideTextAndImageIdeasButtons: false,
                hiddenMediaTypes: {
                    image: false,
                    video: false,
                    proto: false,
                    media: false,
                },
                maxColumnsPerSection: 4,
                maxStatItemsPerSection: 5,
                defaultPadding: 60,
                sectionLimit: null,
                ctsModuleSelected: false
            },
            selectedProject: null,
            selectedSection: null,
            selectedPortfolio: null,
            savingProject: false,
            activeModal: null,
            textEditorActive: false,
            isViewLight: () => {
                let path = Router.currentRoute._value.fullPath;
                return (path.includes('/create-new-portfolio')
                    || path.includes('/home/settings')
                    || path === '/home/portfolios'
                    || path === '/home/notifications'
                    || path === '/home/prices'
                    || path.includes('/home/domain')
                    || path.includes('/home/url')
                    || path.includes('/home/payment')
                    || path.startsWith('/reviews'))
            },
            activeModalData: null,
            activeSectionSettingId: null,
            uploadImageAndCropStep: "upload",
            uploadImageAndCropType: null,
            alertModalMessage: null,
            showSidePanel: false,
            sidePanelSettings: {
                panel: null,
                title: 'Case study design',
                previousSettings: null,
                prevAccentColor: null,
                saveButton: 'Save',
                showSecondaryTab: false,
                hideBackButton: false,
                hideActionButtons: false,
                allowSave: false,
                portfolioPassword: null,
                newPageDetails: null,
                globalSpacingChanged: false,
                editedTextStyle: null,
                activeModal: null,
                goBackPath: null,
                updateParentPortfolio: false,
                tabPills: null,
                activeTab: null,
                storedScrollPosition: null,
                selectedColorPaletteItem: null
            },
            currentSubscriptionPlan: null,
            subscriptionSettingsInfo: null,
            zoomData: {
                isZoomed:false,
                imageUrl:null,
                imageRef:null
            },
            selectedMockupStyle: null,
            showTipMessage: false,
            selectedStatSectionStyles: null,
            selectedTestimonialSectionStyles: null,
            selectedHeaderSectionStyle: null,
            alertMessage: {
                show: false,
                type: 'info',
                message: null,
                interval: null,
            },

            notifications: [],
            showArchiveInfoBubble: false,
            showOnBoardingScreen: false,
            onboardingFinished: false,
            templateTipMessageSeen: false,
            pageIsLoading: false,
            loaderMessage: null,
            creatingNewPortfolio: false,
            previewingTemplate:false,
            previewAndCreatePortfolioDetails: {
                selectedPortfolioTheme:null,
                activeView:0,
                selectedProjects:[],
                newPortfolio: null
            },
            fonts:[],
            reviewCenter: {
                reviewSent: false,
                askForReviewProjectId: null
            },
            darkMode:null,
            //TODO: temp
            view: {
                portfolio:null,
                project:null,
                user:null,
                portfolioPages:null,
                activePage:null,
                footerProjects:[],
                navOpen:false,
                fontsLoaded:false,
                ownDomain:null,
                publicPages: {
                    activeMode: "light"
                },
                showcaseItems: null,
                selectedShowcaseItem: null,
                emergencyModeSettings: null,
                stickedHeader:false //Basically just a field to know if there is a sicked header on case studies. for review mode
            },
            displayToggleGrid: false,
            checklist: {
                activeInteraction:null,
                subpage:false,
                finished:false,
                popUpClosed:true
            },
            blockedEditor: false,
            subscriptionExpiredModalShown: false,
            showPublishTooltip: false,
            eventsToTrack: [],
            showAiFeedbackModal:false,
        }
    },
    mutations: {
        setSelectedPortfolioForDomain(state, portfolioId) {
            state.selectedPortfolioForDomain = portfolioId;
        },
        
        clearSelectedPortfolioForDomain(state) {
            state.selectedPortfolioForDomain = null;
        },
        resetZoom(state) {
            state.zoomData = {
                isZoomed:false,
                imageUrl:null,
                imageRef:null
            }
        },
        setZoom(state, payload) {
            state.zoomData = {
                isZoomed:true,
                imageUrl:payload.url,
                imageRef:payload.ref
            }
        },
        setPageToEdit(state, shortId) {
            if(state.portfolioPages) {
                state.project = state.portfolioPages.find(p => p.shortId === shortId)
                store.commit("setAllowedSections", state.project.type);
                store.commit("setSectionEditorSettings", {
                    pageToEdit: !!state.project ? state.project.type : 'blank',
                    sectionLimit: 3
                });
            }
        },
        setCaseStudyToEdit(state, shortId) {
            state.project = state.caseStudyPages.find(p => p.shortId === shortId)
            store.commit("setAllowedSections", "caseStudy");
        },
        updateEditedPageSectionsInPortfolioPages(state) {
            state.portfolioPages.find(p => p.shortId === state.project.shortId).sections = state.project.sections;
        },
        updateEditedCaseStudySectionsInCaseStudyPages(state) {
            state.caseStudyPages.find(p => p.shortId === state.project.shortId).sections = state.project.sections;
        },
        updateEditedCaseStudyInCaseStudyPages(state) {
            const index = state.caseStudyPages.findIndex(cs => cs._id === state.project._id);
            if (index !== -1) {
                state.caseStudyPages.splice(index, 1, state.project);
            }
        },
        addNewPageToList(state, page) {
            state.portfolioPages.push(page);
        },
        addNewCaseStudyToList(state, caseStudy) {
            state.caseStudyPages.push(caseStudy);
        },
        setActiveModal(state, modal) {
            state.activeModal = modal;
        },
        setAllowedSections(state, type) {
            state.allowedSections = Object.assign({}, {
                basicSections: type !== PageTypesEnum.CASE_STUDY ? aboutPageSections : basicSections,
                recommendedSections: type !== PageTypesEnum.CASE_STUDY ? homePageRecommendedSections : uxMethods
            });
        },
        setSectionEditorSettings(state, obj) {
            state.sectionEditorSettings = Object.assign({}, store.state.sectionEditorSettings, obj);
        },
        selectSection(state, section) {
            state.selectedSection = section ? section : null;
        },
        selectSectionById(state, sectionId) {
            state.selectedSection = state.project.sections.find(s => s._id === sectionId);
        },
        updateSection(state, section) {
            state.project.sections[section.index] = section
            UpdateSection(state.project.sections[section.index])
        },
        selectColumnIndex(state, index) {
            state.selectedColumnIndex = index;
        },
        selectCaseStudy(state, caseStudy) {
            state.selectedProject = caseStudy
        },
        updateColorPalette(state, data) {
            const designSettings = state.project && state.project.type === PageTypesEnum.CASE_STUDY && !state.project.designSettings.globalColorStyles
                ? state.project.designSettings
                : state.portfolio.designSettings;
            designSettings.colorPalette = {
                ...designSettings.colorPalette,
                ...data
            }
        },
        setCurrentPlan(state, plan) {
            state.currentSubscriptionPlan = plan;
        },
        setCurrentSubscription(state, subscription) {
            state.userSettings.currentSubscription = {...subscription};
        },
        updateSectionByIndex(state, obj) {
            state.project.sections[obj.sectionIndex] = {
                ...state.project.sections[obj.sectionIndex],
                ...obj.updatedSectionEntries
            }
        },
        updateProject(state, project) {
            store.state.project = Object.assign({}, store.state.project, project);
        },
        updateSavingState(state) {
            state.savingProject = !state.savingProject;
        },
        updateSidePanel(state, panelStates) {
            state.sidePanelSettings = Object.assign({}, store.state.sidePanelSettings, panelStates);
        },
        updateEventsToTrackList(state, newEvent) {
            // Find the index of the event with the same 'event' value
            const eventIndex = state.eventsToTrack.findIndex(event => event.event === newEvent.event);

            if (!newEvent.nonUniqueEvent && eventIndex !== -1) {
                // If the event exists, update it with the newEvent
                state.eventsToTrack[eventIndex] = newEvent;
            } else {
                // If the event doesn't exist, add the newEvent to the eventsToTrack array
                state.eventsToTrack = [...state.eventsToTrack, newEvent];
            }
        },
        clearEventsToTrackList(state) {
            state.eventsToTrack = [];
        },
        updateSidePanelVisibility(state, val) {
            state.showSidePanel = val;
        },
        updateSelectedSectionImage(state, newImageObj) {
            store.state.selectedSection.image = Object.assign({}, store.state.selectedSection.image, newImageObj);
        },
        updateSelectedSectionMedia(state, newMediaObj) {
            if(typeof store.state.selectedColumnIndex === 'number') {
                switch (store.state.selectedSection.type) {
                    case 'Columns':
                        state.selectedSection['columns'][state.selectedColumnIndex]['media'] = newMediaObj
                        break;
                    case 'Testimonial':
                        state.selectedSection['testimonial'][state.selectedColumnIndex]['media'] = newMediaObj
                        break;
                    case 'LogoWall':
                        state.selectedSection['logos'][state.selectedColumnIndex] = {
                            ...state.selectedSection['logos'][state.selectedColumnIndex],
                            ...newMediaObj
                        }
                        break;
                }
            } else {
                store.state.selectedSection.media = Object.assign({}, store.state.selectedSection.media, newMediaObj);
            }
        },
        updateSectionMediaBasedOnActiveModalData(state, newObj) {
            if(!state.activeModalData.column) {
                // if the image is a portfolio hero background, update portfolio hero
                // else if the image is a portfolio foreground image update portfolio.image
                // else update the selectedSection
                if(state.activeModalData.key === EditedImageTypeEnum.PORTFOLIO_HERO_IMAGE) {
                    state.portfolio.templateSettings.hero.image = newObj
                    state.selectedSection.backgroundColor = null;
                } else if (state.activeModalData.key === EditedImageTypeEnum.PORTFOLIO_HERO_MEDIA) {
                    state.portfolio.image = newObj
                } else if (state.activeModalData.key === EditedImageTypeEnum.THUMBNAIL) {
                    state.selectedProject.image = newObj
                } else  {
                    // if this is a single column section and has image or media object
                    state.selectedSection[state.activeModalData.key] = newObj
                    if(state.activeModalData.key === EditedImageTypeEnum.IMAGE) {
                        state.selectedSection.backgroundColor = null;
                    }
                }
            } else {
                // if this is a multi-column section like Columns, Testimonial, Gallery
                if(state.activeModalData.column.key !== 'logos') {
                    state.selectedSection[state.activeModalData.column.key][state.activeModalData.column.index].media = newObj;
                } else {
                    // if this is a LogoWall section
                    state.selectedSection['logos'][state.activeModalData.column.index] = newObj
                }
            }
        },
        updateGalleryItems(state, newGalleryItems) {
            state.selectedSection.galleryItems = Object.assign([], state.selectedSection.galleryItems, newGalleryItems)
        },
        updateUser(state, user) {
            store.state.user = Object.assign({}, store.state.user, user);
        },
        updateUserInDb(state, field) {
            if(field) {
                event(field, {
                    'event_category': 'Profile editor',
                    'event_label':  `User: ${store.state.user._id}`
                });
            }
            state.savingProject = true;
            UsersAPI.editUser(state.user)
                .then(response => {
                    state.user = {
                        ...response.data,
                        paymentMethods:state.user.paymentMethods
                    }
                })
                .catch(error => {
                    if(error.response.status === 405) {
                        state.currentSubscriptionPlan = error.response.data.currentSubscriptionPlan;
                        this.$store.commit("showAlertMessage", {
                            message: 'Upgrade to Full access to use this feature.',
                            type: 'alert',
                            interval: 10,
                        });
                        if(error.response.data.code === 1005) {
                            state.user.accountSettings.folioLogo = true;
                        }
                    }
                })
                .finally(() => {
                   state.savingProject = false;
                });
        },
        updateExistingStatSectionStyles(state) {
            state.project.sections.find((item) => {
                if(item.statItems && !item.deletedAt && (item.statItems[0].data.style && item.statItems[0].data.style.textStyle || item.statItems[0].description.style && item.statItems[0].description.style.textStyle) ) {
                    return store.state.selectedStatSectionStyles = {data: item.statItems[0].data.style.textStyle, description: item.statItems[0].description.style.textStyle};
                }
            });
        },
        updateExistingHeaderSectionStyle(state) {
            store.state.project.sections.find((item) => {
                if(item.type === 'Header' && item.title.style.textStyle && !item.deletedAt ) {
                    return store.state.selectedHeaderSectionStyle = item.title.style.textStyle;
                }
            });
        },
        updateExistingTestimonialSectionStyles(state) {
            store.state.project.sections.find((item) => {
                if(item.type === 'Testimonial' && !item.deletedAt) {
                    return store.state.selectedTestimonialSectionStyles = {text: item.testimonial[0].text.style.textStyle, author: item.testimonial[0].author.style.textStyle};
                }
            });
        },
        updateFirstMockup(state) {
            store.state.project.sections.find((item) => {
                return store.state.selectedMockupStyle = item.media && item.media.mokcupSettings ? item.media.mockupSettings : (item.galleryItems && item.galleryItems[0].media.mockupSettings ? item.galleryItems[0].media.mockupSettings : defaultMockup);
            });
        },
        openSidePanel(state, data) {
            store.commit("openSidePanelOnPage",data);
        },
        openSidePanelOnPage(state, data) {
            switch(state.project.type) {
                case PageTypesEnum.HOME:
                    Router.push(`/home/portfolios/${store.state.portfolio.shortId}/${data.panelPath}`);
                    break;
                default:
                    Router.push(`/home/portfolios/${store.state.portfolio.shortId}/p/${store.state.project.shortId}/${data.panelPath}`);
                    break;
            }
        },
        closeSidePanel(state) {
            switch(state.project.type) {
                case PageTypesEnum.HOME:
                    Router.push(`/home/portfolios/${state.portfolio.shortId}`);
                    break;
                default:
                    Router.push(`/home/portfolios/${state.portfolio.shortId}/p/${state.project.shortId}`);
                    break;
            }
        },
        toggleSidePanel(state, data) {
            if(state.sidePanelSettings.allowSave) {
                store.commit("updateSidePanel", {
                    activeModal: "CancelConfirmModal",
                    goToPanel: data.panelPath
                })

                return
            }

            if(location.pathname.indexOf(`/${data.panelPath}`) < 0) {
                store.commit("openSidePanel", data)
            } else {
                store.commit("closeSidePanel");
            }
        },
        navigateBetweenSidePanels(state, data) {
            // if there is no data or data.mainPanel the router goes to Design panel
            // if there is no data.targetPanelPath the router goes to main panel
            // targetPanelPath should start with /
            let path;
            const mainPanel = data && data.mainPanel ? data.mainPanel : DesignPanelPathsEnum.ROOT
            switch(state.project.type) {
                case PageTypesEnum.HOME:
                    path = `/home/portfolios/${state.portfolio.shortId}/${mainPanel}`;
                    break;
                default:
                    path = `/home/portfolios/${state.portfolio.shortId}/p/${state.project.shortId}/${mainPanel}`
            }

            if(!data || data && !data.targetPanelPath) return Router.push(path);

            return Router.push(path + data.targetPanelPath);

        },
        setChatBubblePosition(state, x) {
            const widgetContainer = document.querySelector('#freshworks-container');
            const widgetLauncher = document.querySelector('#launcher-frame');

            if(!!widgetContainer) {
                widgetContainer.style.cssText += `--leftPos: ${x}px;`;
                if(store.getters.showTrialPrompt) widgetContainer.style.cssText += `--bottomBubPos: 51px; --bottomPopPos: 109px`;
            }
            if(!!widgetLauncher) {
                widgetLauncher.style.cssText += `--leftPos: ${x}px;`;
            }
        },
        async openPortfolioToEdit(state, portfolioId) {
            await PortfolioActions.getPortfolios(portfolioId);
            store.commit("setPageToEdit", "home");
            await Router.push(`/home/portfolios/${state.portfolio.shortId}`)
        },
        setHeightOfTextareas(state) {
            autosize(document.querySelectorAll('textarea'));
            setTimeout(() => { autosize.update(document.querySelectorAll('textarea')) }, 10);
        },
        scrollToSection(state, data) {
            let s = document.querySelector(`#section-${data.sectionIndex}`);
            if(!!s) {
                s.classList.toggle('restore.stated', !!data.restoreSection);
                let scrollVal = offset(s) - (s.offsetHeight <= window.innerHeight ? ((window.innerHeight - s.offsetHeight) / 2 + 29) : 58);
                window.scrollTo({
                    top: scrollVal,
                    left: 0,
                    behavior: 'smooth'
                });
            }
            function offset(el) {
                const rect = el.getBoundingClientRect(),
                    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                return rect.top + scrollTop
            }
        },
        showAlertMessage(state, data) {
            state.alertMessage = {
                type: data.type,
                message: data.message,
                show: data.show === false ? data.show : true,
                interval: data.interval ? data.interval : 3,
            }
        },
        scrollToItem(state, item) {
            let scrollToItem = item.index-1 > -1 ? document.querySelector(`[data-id="${item.index-1}"]`) : null;
            let scrollToSection = document.querySelector(`[data-section-id="${store.state.selectedSection.index}"]`);
            let scrollVal = scrollToSection.offsetTop - (scrollToSection.offsetHeight <= window.innerHeight ? ((window.innerHeight - scrollToSection.offsetHeight) / 2 + 29) : 58) + (scrollToItem ? scrollToItem.offsetTop : 0);
            window.scrollTo({top: scrollVal, left: 0, behavior: 'smooth'});
        },
        selectTextStyleToEdit(state, textStyle) {
            state.sidePanelSettings.editedTextStyle = textStyle;
        },
        updateColorInTextStyle(state, color) {
            // if the edited color is not inside a text style object (accentColor, backgroundColor)
            if(!state.sidePanelSettings.editedTextStyle.type) {
                return state.sidePanelSettings.editedTextStyle = color;
            }

            state.sidePanelSettings.editedTextStyle.color = color;
        },
        updateSelectedTextStyle(state, textStyle) {
            state.sidePanelSettings.editedTextStyle = textStyle
        },
        updatePortfolioInUserPortfolios(state, portfolio) {
            const portfolioIndex = state.userPortfolios.findIndex(p => p.shortId === portfolio.shortId)
            state.userPortfolios[portfolioIndex] = portfolio;
        },
        openLink(state, link) {
            window.open(link);
        },
        updateView(state, view) {
            state.view = {...state.view, ...view}
        },
        recalculateScrollTriggers(state) {
            state.recalculateScrollTrigger = !state.recalculateScrollTrigger;
        },
        updateChecklistItem(state, step) {
            //Only do anything if value not null. For all old users value will be null
            if(state.user.checklistStatus) {
                state.user.checklistStatus.steps[step] = true;
                state.checklist.activeInteraction = null;

                let action = 'Finished: ' + step;
                event(action, {
                    'event_category': 'Activation checklist',
                    'event_label': `User: ${state.user._id}`
                });

                store.commit('updateUserInDb');
            }
        },
        updateChecklistFinished(state, finished) {
            state.checklist.finished = finished;
        },
        updateActivationPopupState(state, value) {
            state.checklist.popUpClosed = value;
        },
        setPortfoliosToDraft(state) {
            if (state.userPortfolios && state.userPortfolios.length) {
                state.userPortfolios.forEach(portfolio => {
                    portfolio.draft = true;
                })
            }
            if (state.portfolio) {
                state.portfolio.draft = true;
            }
            if (state.selectedPortfolio) {
                state.selectedPortfolio.draft = true;
            }
        },
        setBlockedEditor(state, blockedEditor) {
            state.blockedEditor = blockedEditor;
        },
        setSubscriptionExpiredModalShown(state) {
            state.subscriptionExpiredModalShown = true;
        },
        setTextEditorActive(state, active) {
            state.textEditorActive = active;
        }
    },
    getters: {
        isEditorBlocked() {
            return !!store.state.blockedEditor;
        },
        isTextEditorActive() {
            return store.state.textEditorActive;
        },
        countPublishedPortfolios() {
            return store.state.userPortfolios.filter(portfolio => !portfolio?.draft).length
        },
        countPublishedCaseStudies() {
            return store.state.caseStudyPages.filter(caseStudy => !caseStudy?.draft).length
        },
        isDefaultAccentColor() {
            if(!store.state.project.settings || store.state.project.settings.colors && !store.state.project.settings.colors.accent) {
                return true;
            }
        },
        userIsTrial() {
            return store.state.currentSubscriptionPlan === 'Trial';
        },
        userIsFreeOrTrial() {
            return store.state.currentSubscriptionPlan === 'Free' || store.state.currentSubscriptionPlan === 'Trial'
        },
        userIsPremium() {
            return !store.getters.userIsFreeOrTrial && !store.getters.userIsCanceledPremium && !store.getters.userIsPastDue;
        },
        userIsPastDue() {
            return store.state.userSettings.currentSubscriptionPlan === 'Premium' && store.state.userSettings.currentSubscription?.status === 'past_due'
        },
        userIsCanceledPremium() {
            return store.state.userSettings.currentSubscriptionPlan === 'Premium' && store.state.userSettings.currentSubscription?.status === 'canceled'
        },
        userIsVIP() {
            return store.state.currentSubscriptionPlan === 'VIP'
        },
        userHasAiToken() {
            return store.state.user.aiToken > 0;
        },
        caseStudies(state) {
            return state.caseStudyPages
        },
        getAllTextStylesInCss(state, getters) {
            const fontStyles = store.getters.fontStyles;
            const colorStyles = store.getters.colorStyles;
            const colorPalette = store.getters.colorPalette;
            const ctaStyle = store.getters.isCaseStudy && !store.getters.usingGlobalFontStyles ? store.state.project.settings.ctaStyle : store.state.portfolio.templateSettings.ctaStyle;

            let borderColor = colorStyles.navigation;
            if (borderColor.color < 8) {
                borderColor.color += store.state.portfolio.templateSettings.theme === 'profile_theme_default_old' ||
                (store.state.portfolio.templateSettings.theme !== 'profile_theme_seven' &&
                    store.state.portfolio.templateSettings.theme !== 'theme_thirteen') ? '33' : '';
            }

            let textStylesCssVars = {};

            // process text related styles based on styleProperties list
            textStylesCssVars = {
                ...getAllTextStyles(fontStyles, colorStyles, colorPalette, ctaStyle)
            };

            // add none text related styles and colors to the cssVars object
            textStylesCssVars = {
                ...textStylesCssVars,
                '--border-color': resolveColor(borderColor, colorPalette),
                '--link-color': resolveColor(colorStyles.hyperLink, colorPalette),
                '--link-hover-color': resolveColor(colorStyles.hyperLink, colorPalette) + "CC",
                '--background-color': resolveColor(colorStyles.background, colorPalette),
                '--divider-line-color': resolveColor(colorStyles.dividerLine, colorPalette),
                '--sidebar-background-color': resolveColor(colorStyles.sidebarBackground, colorPalette)
            }

            // In the case of sidebar templates we need to load portfolio hero fonts and colors in the case studies too
            if(
                ['theme_nine', 'profile_theme_nine', 'theme_four', 'profile_theme_four'].includes(store.state.portfolio.templateSettings.theme)
            ) {
                const portfolioFontStyles = store.state.portfolio.designSettings.fontStyles;
                const portfolioColorStyles = store.state.portfolio.designSettings.colorStyles;
                const portfolioColorPalette = store.state.portfolio.designSettings.colorPalette;
                const portfolioBorderColor = resolveColor(portfolioColorStyles.navigation, portfolioColorPalette) + '33';

                // process text related styles based on portfolioStyleProperties list
                textStylesCssVars = {
                    ...textStylesCssVars,
                    ...getPortfolioHeroTextStyles(portfolioFontStyles, portfolioColorStyles, portfolioColorPalette)
                };

                // add none text related styles and colors to the cssVars object
                textStylesCssVars = {
                    ...textStylesCssVars,
                    '--portfolio-border-color': portfolioBorderColor,
                    '--portfolio-link-color': resolveColor(portfolioColorStyles.hyperLink, portfolioColorPalette),
                    '--portfolio-link-hover-color': resolveColor(portfolioColorStyles.hyperLink, portfolioColorPalette) + "CC",
                    '--portfolio-sidebar-background-color': resolveColor(portfolioColorStyles.sidebarBackground, portfolioColorPalette)
                }
            }

            textStylesCssVars = {
                ...textStylesCssVars,
                ...getCssVarsFromColorPalette(colorPalette)
            }

            return textStylesCssVars;
        },
        pageViewURL(state) {
            const root = !!state.portfolio.ownDomainAddress && !state.project.draft ? `https://${state.portfolio.ownDomainAddress}` : location.origin;
            const subPagePath = Router.currentRoute.value.params.projectShortId;
            let url = !!state.portfolio.ownDomainAddress ? root : `${root}/${state.portfolio.shortId}`;

            // set path
            if(!!subPagePath) {
                if(!!state.portfolio.ownDomainAddress && !state.project.draft) {
                    url = `${root}/p/${subPagePath}`
                } else {
                    url = `${root}/p/${state.portfolio.shortId}/${subPagePath}`
                }
            }

            return url
        },
        isHomePage(state) {
            return state.project && state.project.type === PageTypesEnum.HOME;
        },
        isCaseStudy(state) {
            return state.project && state.project.type === PageTypesEnum.CASE_STUDY;
        },
        usingGlobalFontStyles(state, getters) {
            return getters.isCaseStudy && state.project.designSettings.globalFontStyles;
        },
        usingGlobalColorStyles(state, getters) {
            return getters.isCaseStudy && state.project.designSettings.globalColorStyles;
        },
        animationSettings(state) {
            return state.project && state.project.type === PageTypesEnum.CASE_STUDY && !state.project.designSettings.globalAnimationSettings ?
                state.project.designSettings && state.project.designSettings.animationSettings ? state.project.designSettings.animationSettings : null :
                (state.portfolio && state.portfolio.designSettings) && state.portfolio.designSettings.animationSettings ? state.portfolio.designSettings.animationSettings : null;
        },
        ctsIsActive(state) {
            return state.sectionEditorSettings.ctsModuleSelected
        },
        designSettings(state, getters) {
            return getters.isCaseStudy && !getters.usingGlobalColorStyles
                ? state.project.designSettings
                : state.portfolio.designSettings;
        },
        fontStyles(state, getters) {
            return getters.isCaseStudy && !getters.usingGlobalFontStyles
                ? state.project.designSettings.fontStyles
                : state.portfolio.designSettings.fontStyles;
        },
        colorStyles(state, getters) {
            return getters.isCaseStudy && !getters.usingGlobalColorStyles
                ? state.project.designSettings.colorStyles
                : state.portfolio.designSettings.colorStyles;
        },
        colorPalette(state, getters) {
            const colorStyles = getters.colorStyles;

            if (getters.designSettings.colorPalette?.colors?.length > 0) {
                return getters.designSettings.colorPalette;
            }

            // Default colors if colorPalette is empty or not defined
            return {
                colors: [
                    colorStyles.heroTitle.primaryColor || colorStyles.heroTitle.color,
                    colorStyles.heroParagraph.primaryColor || colorStyles.heroParagraph.color,
                    colorStyles.hyperLink.primaryColor || colorStyles.hyperLink.color,
                    colorStyles.navigation.primaryColor || colorStyles.navigation.color,
                    colorStyles.background.primaryColor || colorStyles.background.color,
                ]
            }
        },
        showTrialPrompt(state, getters) {
            return !Router.currentRoute.value.meta.nonEditorPage
                && !state.onboardingFinished
                && state.userSettings.currentSubscriptionPlan
                && !getters.userIsVIP
                && (getters.userIsTrial || !getters.userIsPremium)
        }
    },
    actions: {
        async trackAndClearEvents({ commit, state }) {
            try {
                if (state.eventsToTrack.length === 0) {
                    console.log('No events to track.');
                    return;
                }

                for (const e of state.eventsToTrack) {
                    await MixpanelService.track(e.event, e.properties); // assuming MixpanelService.track is async
                }

                commit('clearEventsToTrackList');
            } catch (error) {
                console.error('Error while tracking events:', error);
            }
        },
        showChatBubble() {
            let path = Router.currentRoute.value.fullPath;
            if(window.fwSettings && !store.state.onboardingFinished && !path.includes("/home/onboarding")) {
                setTimeout(() => {
                    window.FreshworksWidget('show', 'launcher');
                }, 5000);
            }
        },
    },

});

store.subscribe((mutation, state) => {

    if (mutation.type === 'setBlockedEditor') {
        if (mutation.payload) {
            state.showSidePanel = false;
            store.commit("setPortfoliosToDraft");

            const currentPath = Router.currentRoute.value.fullPath;
            if (!['/home/prices', '/home/settings', '/home/payment'].some(path => currentPath.includes(path))
                && !state.subscriptionExpiredModalShown) {
                store.commit("setActiveModal", "SubscriptionExpiredModal");
            }
        }
    }
});

