import ApiHelper from '../ApiHelper.js';

const PortfoliosApiUrl = "/api/portfolio/";

function updateImageFromBlob(url, blob, fileName, path, filesToDelete) {
    const data = new FormData();
    data.append('imageFile', blob, fileName);
    data.append('path', path);
    data.append('filesToDelete', filesToDelete)
    return ApiHelper.postMultipart(url, data);
}

export default {
    moveDomainToPortfolio: function(domainName, targetPortfolioId) {
        return ApiHelper.post(`${PortfoliosApiUrl}moveDomain/${targetPortfolioId}/${domainName}`);
    },
    
    getPortfolio: function (portfolioId) {
        return ApiHelper.get(PortfoliosApiUrl + portfolioId);
    },

    getPortfolios: function (ownerId) {
        return ApiHelper.get(`${PortfoliosApiUrl}getByUserId/`  + ownerId);
    },

    createPortfolio: function (userId, portfolio, projects) {
        return ApiHelper.post(PortfoliosApiUrl + userId, {portfolio, projects});
    },

    duplicatePortfolio: function (userId, portfolio, projects) {
        return ApiHelper.post(`${PortfoliosApiUrl}duplicate/` + userId, {portfolio, projects});
    },

    editPortfolio: function (portfolio) {
        return ApiHelper.put(PortfoliosApiUrl, portfolio);
    },

    setTemplateForPortfolio: function (portfolio) {
        return ApiHelper.put(`${PortfoliosApiUrl}set-template`, portfolio);
    },

    addDefaultPortfolioPages(portfolio) {
        return ApiHelper.put(`${PortfoliosApiUrl}addDefaultPages`, portfolio)
    },

    deletePortfolio: function (portfolioId) {
        return ApiHelper.delete(PortfoliosApiUrl + portfolioId);
    },

    getPortfolioTemplateSettings(portfolioId) {
        return ApiHelper.get(`${PortfoliosApiUrl}${portfolioId}/template-settings`)
    },

    getPortfolioTemplateAndSettings(portfolioId) {
        return ApiHelper.get(`${PortfoliosApiUrl}${portfolioId}/template-design-settings`)
    },

    getPortfolioSettings(portfolioId) {
        return ApiHelper.get(`${PortfoliosApiUrl}${portfolioId}/settings`)
    },

    setPortfolioSettings(portfolioId, settings) {
        return ApiHelper.put(`${PortfoliosApiUrl}${portfolioId}/settings`, settings)
    },

    setPortfolioPassword: function (portfolioId, password) {
        return ApiHelper.put(PortfoliosApiUrl + portfolioId + "/password", {password: password});
    },

    setPortfolioDraft: function (portfolioId, draft) {
        return ApiHelper.put(PortfoliosApiUrl + portfolioId + "/draft", {draft: draft});
    },

    deletePortfolioImage(portfolio, imageUrl) {
        return ApiHelper.delete("/api/image/portfolio/delete/" + portfolio, {url: imageUrl});
    },

    setDomainForPortfolio: function(portfolioId, domain) {
        return ApiHelper.post(PortfoliosApiUrl + "domain/" + portfolioId + "/" + domain);
    },

    updatePortfolioUrl: function (portfolioId, urlSlug) {
        return ApiHelper.put(PortfoliosApiUrl + "updateUrl/" + portfolioId + "/" + urlSlug);
    },

    uploadCroppedImage: function (userId, portfolioId, blob, imageType, extension, imageToDelete) {
        return updateImageFromBlob("/api/image/portfolio/upload/" + portfolioId + "/" + imageType, blob, 'croppedImage.' + extension, userId + "/portfolios/" + portfolioId, [imageToDelete]);
    },

    updatePortfolioLogo(logo,portfolioId) {
        return ApiHelper.put(`${PortfoliosApiUrl}updateLogo/${portfolioId}`, logo)
    },

    setPortfolioGlobalSpacing(project) {
        return ApiHelper.put(`${PortfoliosApiUrl}setGlobalSpacing`, project)
    }
}
